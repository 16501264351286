import React from "react";
import { object, array, string, number, any } from "prop-types";
import { connect } from "react-redux";
import { Link } from "@quintype/components";
import get from "lodash/get";

import TheQuintLogo from "../../atoms/icons/the-quint-logo";
import HindiQuintLogo from "../../atoms/icons/hindi-quint-logo";
import MenuItem from "../../atoms/menu-item";
import LinkView from "../../atoms/link-view";
import FitColorLogo from "../../atoms/icons/fit-color-logo";
import SocialIcons from "../../molecules/social-icons";
import AlsoWatch from "../../molecules/also-watch";

import { captureBottomNavigationClick } from "../../helper/datalayer-utils";

import translate from "../../translate";

import "./footer.m.css";

const Footer = ({
  member,
  isActivePlan,
  activePlanId,
  secondaryMenuItems,
  trendingTopics,
  publisherAttributes,
  publisherName,
  currentHostUrl,
  pageType,
  currentPath,
}) => {
  const captureDLNavigationClick = (name) => {
    setTimeout(captureBottomNavigationClick(name, member && member, activePlanId), 1000);
  };

  const getLogoView = () => {
    if (publisherName === "quint-hindi") {
      return <HindiQuintLogo position="footer" member={member} />;
    } else if (publisherName === "quint" && currentPath.split("/")[1] === "fit") {
      return <FitColorLogo width={107} height={60} position="footer" member={member} />;
    } else {
      return (
        <TheQuintLogo
          width={95}
          height={60}
          position="footer"
          member={member}
          isActivePlan={isActivePlan}
          activePlanId={activePlanId}
        />
      );
    }
  };

  const getQuintLinkView = () => (
    <LinkView className="menu-item-link" link="https://www.thequint.com/">
      <div onClick={() => captureDLNavigationClick("Quint")}>Quint</div>
    </LinkView>
  );

  const getFitLinkView = () => (
    <LinkView
      className="menu-item-link"
      link={
        (publisherAttributes && publisherAttributes.fit_url) ||
        (publisherName === "quint-hindi" ? "https://hindi.thequint.com/fit" : "https://www.thequint.com/fit")
      }
    >
      <div onClick={() => captureDLNavigationClick("FIT")}>FIT</div>
    </LinkView>
  );

  const getQuintHindiLinkView = () => (
    <LinkView className="menu-item-link" link="https://hindi.thequint.com/">
      <div onClick={() => captureDLNavigationClick("Quint Hindi")}>Quint Hindi</div>
    </LinkView>
  );

  const getWebsiteLinks = () => {
    if (publisherName === "quint-hindi") {
      return (
        <React.Fragment>
          {getQuintLinkView()}
          {getFitLinkView()}
        </React.Fragment>
      );
    }

    if (publisherName === "fit") {
      return (
        <React.Fragment>
          {getQuintHindiLinkView()}
          {getQuintLinkView()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {getQuintHindiLinkView()}
        {getFitLinkView()}
      </React.Fragment>
    );
  };

  return (
    <>
      <AlsoWatch index={0} />
      <div styleName="wrapper">
        <div className="container">
          <div styleName="content">
            {/* Sections */}
            {pageType === "not-available-page" ? null : (
              <div>
                <div styleName="name">SECTIONS</div>
                <ul styleName="menu-list">
                  {secondaryMenuItems.map((item, index) => (
                    <li key={index} styleName="menu-item" className={`footer-sections-${index + 1}`}>
                      <MenuItem
                        id={`ga4-footer-sections-${index + 1}`}
                        menuPosition="footer"
                        item={item}
                        currentHostUrl={currentHostUrl}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Trending Topics */}
            {pageType === "not-available-page" ? null : (
              <div styleName="trending">
                <div styleName="name">TRENDING TOPICS</div>
                <ul styleName="menu-list">
                  {trendingTopics.map((item, index) => (
                    <li key={index} styleName="menu-item" className={`footer-trending-topic-${index + 1}`}>
                      <MenuItem
                        id={`ga4-footer-trending-${index + 1}`}
                        item={item}
                        currentHostUrl={currentHostUrl}
                        menuPosition="footer"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Others */}
            <div styleName="others">
              <Link
                href={currentPath.split("/")[1] === "fit" ? "/fit" : "/"}
                styleName="main-logo"
                aria-label="The Quint homepage"
              >
                {getLogoView()}
              </Link>

              {/* Soical Icons */}
              <div styleName="follow-us">
                <div styleName="follow-us-name">{translate(publisherName, "follow_us_on")}</div>
                <SocialIcons />
              </div>

              {/* Sections and Other Links */}
              <div styleName="web-links">
                <div>
                  {/* <LinkView className="menu-item-link" link="https://www.bqprime.com/">
                    <div onClick={() => captureDLNavigationClick("BQ Prime")}>BQ Prime</div>
                  </LinkView> */}

                  {getWebsiteLinks()}
                </div>

                <div>
                  <LinkView className="menu-item-link" link="/about-us" openInNewTab={false}>
                    <div onClick={() => captureDLNavigationClick("About Us")}>
                      {translate(publisherName, "about_us")}
                    </div>
                  </LinkView>

                  <LinkView className="menu-item-link" link="/contact-us" openInNewTab={false}>
                    <div onClick={() => captureDLNavigationClick("Contact Us")}>
                      {translate(publisherName, "contact_us")}
                    </div>
                  </LinkView>

                  <LinkView className="menu-item-link" link="/privacy-policy" openInNewTab={false}>
                    <div onClick={() => captureDLNavigationClick("Privacy Policy")}>
                      {translate(publisherName, "privacy_policy")}
                    </div>
                  </LinkView>

                  <LinkView className="menu-item-link" link="/terms-and-conditions" openInNewTab={false}>
                    <div onClick={() => captureDLNavigationClick("T&C")}>{translate(publisherName, "t_and_c")}</div>
                  </LinkView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  secondaryMenuItems: array,
  trendingTopics: any,
  publisherAttributes: object,
  publisherName: string,
  currentHostUrl: string,
  pageType: string,
  currentPath: string,
};

const mapStateToProps = (state) => ({
  member: state.member,
  isActivePlan: get(state, ["userReducer", "isActivePlan"]),
  activePlanId: get(state, ["userReducer", "activePlanId"], null),
  secondaryMenuItems: get(state, ["qt", "data", "navigationMenu", "secondaryMenuItems"], []),
  trendingTopics: get(state, ["qt", "data", "navigationMenu", "trendingTopics"], []),
  publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], {}),
  publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
  currentHostUrl: get(state, ["qt", "currentHostUrl"], ""),
  pageType: get(state, ["qt", "pageType"]),
  currentPath: get(state, ["qt", "currentPath"], ""),
});

export default connect(mapStateToProps, null)(Footer);
