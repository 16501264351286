/* eslint-disable max-len */
import React from "react";
import { number, string, object } from "prop-types";

// import { captureTopNavigationClick, captureBottomNavigationClick } from "../../../helper/datalayer-utils";

const TheQuintLogo = ({ color = "#fff", width = 90, height = 50, position, member, isActivePlan, activePlanId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 66 42"
    // onClick={() => {
    //   position === "footer"
    //     ? setTimeout(
    //         captureBottomNavigationClick(
    //           "TheQuint",
    //           member && member,
    //           isActivePlan && isActivePlan,
    //           activePlanId && activePlanId
    //         ),
    //         1000
    //       )
    //     : setTimeout(
    //         captureTopNavigationClick(
    //           "TheQuint",
    //           member && member,
    //           isActivePlan && isActivePlan,
    //           activePlanId && activePlanId
    //         ),
    //         1000
    //       );
    // }}
  >
    <path d="M0 0h66v42H0z" fill="rgba(255,255,255,0)" />
    <g transform="translate(3.438 3.443)">
      <path
        d="M13.317 19.3l-1.226-1.325c-1.613-1.767-3.27-3.528-4.889-5.311a.924.924 0 0 0-.753-.329c-.984 0-1.974-.032-2.958-.092a3.906 3.906 0 0 1-1.952-.6A3.334 3.334 0 0 1 .13 9.489 6.921 6.921 0 0 1 0 7.84V6.332c0-.883.028-1.761.06-2.644A7.4 7.4 0 0 1 .3 1.84 2.357 2.357 0 0 1 2.271.079 6.75 6.75 0 0 1 4.008.03q1.2.092 2.4.259a27.929 27.929 0 0 1 2.806.485 3.266 3.266 0 0 1 2.4 2.154 13.547 13.547 0 0 1 .538 2.154c.258 1.26.489 2.521.715 3.77a4.256 4.256 0 0 1 0 1.659 1.927 1.927 0 0 1-1.409 1.5l-.221.059c-.188.043-.188.043-.134.215l2.189 6.932v.083z"
        fill="#febf0f"
        transform="translate(.009 .004)"
      />
      <path
        d="M27.489 43.012a3.3 3.3 0 0 1-.995 1.11 2.688 2.688 0 0 1-4.13-1.616 4.725 4.725 0 0 1 .1-2.5 6.608 6.608 0 0 1 2.985-4.024 4.926 4.926 0 0 1 2.651-.743 1.544 1.544 0 0 1 1.54 1.051 6.062 6.062 0 0 0 .312-.539.538.538 0 0 1 .436-.312c.662-.1 1.318-.221 1.974-.339h.07l-.64 2.036q-2.206 7.064-4.422 14.15c-.038.113-.075.162-.2.162h-2.155c-.14 0-.156-.032-.118-.162q1.194-3.77 2.377-7.492c.081-.253.151-.5.226-.754 0 0 .032-.048 0-.059s-.011.019-.011.031zm-2.889-.425a3.975 3.975 0 0 0 .032.539.791.791 0 0 0 1.076.77 1.968 1.968 0 0 0 1.027-.668 8.18 8.18 0 0 0 1.613-2.876c.344-.991.64-1.993.963-2.989a1.025 1.025 0 0 0 .038-.506 1.3 1.3 0 0 0-1.78-1.029 2.5 2.5 0 0 0-1.076.883 8.111 8.111 0 0 0-1.2 2.391 11.978 11.978 0 0 0-.693 3.485z"
        fill={color}
        transform="translate(-10.273 -16.192)"
      />
      <path
        d="M47.937 11.832c.059 0 .07-.086.1-.129a7.307 7.307 0 0 1 1.667-2 2.949 2.949 0 0 1 2.388-.641 1.567 1.567 0 0 1 1.328 1.47 5.652 5.652 0 0 1-.382 2.408c-.3.856-.721 1.664-1.076 2.494a4.313 4.313 0 0 0-.312.916 1.376 1.376 0 0 0 0 .619.581.581 0 0 0 .608.469 1.424 1.424 0 0 0 1.076-.506 4.067 4.067 0 0 0 .538-.77c.043-.081.091-.1.167-.054l.086.038c.29.118.3.124.129.4a3.561 3.561 0 0 1-2.533 1.788 2.02 2.02 0 0 1-1.656-.339 1.525 1.525 0 0 1-.581-1.228 4.568 4.568 0 0 1 .393-1.783c.3-.765.672-1.5.936-2.273a4.558 4.558 0 0 0 .285-1.53 1.785 1.785 0 0 0-.043-.361.576.576 0 0 0-.683-.479 1.817 1.817 0 0 0-1.113.566 8.536 8.536 0 0 0-1.215 1.643c-.075.118-.14.248-.221.366a9.892 9.892 0 0 0-1.129 2.817c-.242.765-.484 1.535-.715 2.305a.161.161 0 0 1-.194.135h-2.113c-.134 0-.161 0-.118-.162q1.215-3.883 2.42-7.767l1.812-5.828c.054-.172.043-.183-.134-.183h-1c-.1 0-.124 0-.1-.118.1-.388.1-.393.495-.4l2.856-.059h.366c.188 0 .188 0 .129.183l-.748 2.4-1.694 5.456c-.008.034-.04.083-.019.137z"
        fill={color}
        transform="translate(-20.116 -1.681)"
      />
      <path
        d="M48.491 41.792c-.113.183-.221.361-.333.539a6.46 6.46 0 0 1-1.613 1.8 2.621 2.621 0 0 1-2.366.4A1.664 1.664 0 0 1 42.994 43a5.933 5.933 0 0 1 .328-2.154c.29-.932.715-1.8 1.076-2.693a6.178 6.178 0 0 0 .307-.889 1.575 1.575 0 0 0 0-.695.409.409 0 0 0-.538-.366 1.194 1.194 0 0 0-.538.42 2.979 2.979 0 0 0-.387.6c-.038.075-.075.092-.151.059a2.241 2.241 0 0 0-.3-.1c-.086 0-.081-.065-.048-.129a2.867 2.867 0 0 1 2.151-1.734 2.325 2.325 0 0 1 1.022.043 1.405 1.405 0 0 1 1.076 1.282 3.9 3.9 0 0 1-.355 1.847c-.285.716-.608 1.422-.9 2.154a4.854 4.854 0 0 0-.4 1.724 1.774 1.774 0 0 0 .032.426.608.608 0 0 0 .748.539 2 2 0 0 0 1.264-.776 11.974 11.974 0 0 0 1.269-1.869c.075-.129.145-.269.226-.393a6.809 6.809 0 0 0 .688-1.81c.307-.948.6-1.9.887-2.855a.151.151 0 0 1 .156-.129l2.2-.108c.145 0 .14.038.108.156q-.769 2.445-1.533 4.9-.409 1.309-.817 2.623c-.129.415.038.63.468.652a1.285 1.285 0 0 0 1.113-.539 4.767 4.767 0 0 0 .463-.711c.038-.07.075-.092.151-.059a3.354 3.354 0 0 0 .328.113c.065 0 .081.048.043.108a3.518 3.518 0 0 1-2.49 2.02 2.047 2.047 0 0 1-1.533-.275 1.643 1.643 0 0 1-.731-1.271 3.236 3.236 0 0 1 .1-1.131.2.2 0 0 0 .014-.188z"
        fill={color}
        transform="translate(-19.739 -16.275)"
      />
      <path
        d="M80.245 38.17c.1-.162.2-.318.3-.469a6.752 6.752 0 0 1 1.533-1.734 3.052 3.052 0 0 1 2.205-.641 1.615 1.615 0 0 1 1.49 1.616 6.148 6.148 0 0 1-.479 2.51c-.307.824-.721 1.616-1.076 2.418a3.586 3.586 0 0 0-.269.883 1.246 1.246 0 0 0 .032.469.581.581 0 0 0 .629.479 1.532 1.532 0 0 0 1.167-.652 3.576 3.576 0 0 0 .414-.6c.054-.108.118-.118.21-.065h.043c.307.124.312.129.134.42a3.582 3.582 0 0 1-2.522 1.777 2.331 2.331 0 0 1-1.113-.054 1.616 1.616 0 0 1-1.151-1.767 7.36 7.36 0 0 1 .672-2.154 13.937 13.937 0 0 0 .812-2.154 3.97 3.97 0 0 0 .118-1.077c0-.636-.333-.878-.952-.727a1.882 1.882 0 0 0-.861.539 8.219 8.219 0 0 0-1.232 1.67c-.167.275-.312.539-.484.824a6.868 6.868 0 0 0-.672 1.734q-.468 1.454-.909 2.914a.167.167 0 0 1-.194.14h-2.153c-.124 0-.156 0-.113-.151q1.162-3.528 2.28-7.067a1.413 1.413 0 0 0 .075-.4.538.538 0 0 0-.785-.539 1.076 1.076 0 0 0-.463.355 2.909 2.909 0 0 0-.376.63c-.038.086-.086.119-.172.07h-.07c-.317-.108-.323-.113-.156-.4a2.991 2.991 0 0 1 2.152-1.567 1.9 1.9 0 0 1 1.226.151 1.616 1.616 0 0 1 .828 1.379 3.544 3.544 0 0 1-.1 1.131.216.216 0 0 0-.018.109zm-.032.043z"
        fill={color}
        transform="translate(-35.031 -16.289)"
      />
      <path
        d="M100.418 33.223h-1.361c-.151 0-.161-.043-.129-.172.1-.382.091-.382.484-.382h1.033c.118 0 .161-.043.194-.151q.4-1.333.812-2.655a.178.178 0 0 1 .194-.151c.721 0 1.447-.043 2.151-.081.14 0 .145.032.108.151-.29.9-.575 1.8-.866 2.693-.059.183-.059.183.134.183h1.259c.1 0 .129 0 .108.129-.075.42-.081.426-.506.426h-1.076c-.091 0-.14 0-.172.118q-1.1 3.528-2.216 7.051a.933.933 0 0 0-.048.28.409.409 0 0 0 .43.431 1.494 1.494 0 0 0 1.215-.614 3.874 3.874 0 0 0 .441-.652c.048-.086.1-.1.177-.059s.312.075.344.162-.1.242-.167.355a3.33 3.33 0 0 1-2.221 1.675 2.589 2.589 0 0 1-1.038.032 1.615 1.615 0 0 1-1.28-1.39 3.377 3.377 0 0 1 .151-1.557l1.807-5.7a1.267 1.267 0 0 0 .038-.122z"
        fill={color}
        transform="translate(-45.47 -13.668)"
      />
      <path
        d="M38.289 11.541h-.624c-.21 0-.183.032-.221.162q-.893 2.871-1.8 5.736l-.4 1.277a1.186 1.186 0 0 0-.048.2.425.425 0 0 0 .441.512 1.462 1.462 0 0 0 1.14-.539 4.061 4.061 0 0 0 .538-.733c.043-.075.081-.092.151-.054h.043c.113.048.3.059.323.156s-.1.221-.161.329a3.33 3.33 0 0 1-2.227 1.691 2.229 2.229 0 0 1-1.388-.07 1.648 1.648 0 0 1-.995-1.454 4.223 4.223 0 0 1 .28-1.707c.57-1.777 1.129-3.56 1.7-5.338.038-.124 0-.156-.108-.151h-1.226c-.129 0-.14-.048-.118-.151.086-.4.086-.4.5-.4H35.1a.184.184 0 0 0 .21-.156q.4-1.333.807-2.655a.167.167 0 0 1 .183-.145l2.184-.081c.118 0 .145 0 .108.14-.3.91-.581 1.821-.877 2.731-.038.124-.038.172.118.167h1.275c.113 0 .14.032.118.14-.086.415-.081.415-.5.415z"
        fill={color}
        transform="translate(-15.276 -3.675)"
      />
      <path
        d="M65.608 19.479a6.1 6.1 0 0 1 3.679-5.559 4.033 4.033 0 0 1 3.049-.054 1.884 1.884 0 0 1 1.232 1.4 1.93 1.93 0 0 1-.678 1.934 4.553 4.553 0 0 1-1.925.953 9.855 9.855 0 0 1-2.582.3.172.172 0 0 0-.2.145 7.8 7.8 0 0 0-.382 2.51 2.4 2.4 0 0 0 .1.646.9.9 0 0 0 .871.679 3.433 3.433 0 0 0 2.426-.786 7.663 7.663 0 0 0 .85-.8c.07-.075.108-.075.172 0 .226.28.231.275 0 .539a4.673 4.673 0 0 1-2.3 1.438 3.454 3.454 0 0 1-2.947-.458 3.232 3.232 0 0 1-1.3-2.09 3.42 3.42 0 0 1-.065-.797zm2.737-1.546a5.1 5.1 0 0 0 1.915-.242 1.733 1.733 0 0 0 1.011-.851 3.452 3.452 0 0 0 .226-2.208.538.538 0 0 0-.538-.458 1.343 1.343 0 0 0-1.076.307 2.6 2.6 0 0 0-.592.765 8.751 8.751 0 0 0-.624 1.562c-.1.372-.21.743-.328 1.126z"
        fill={color}
        transform="translate(-30.317 -6.269)"
      />
      <path
        d="M64.606 42.918a5.393 5.393 0 0 1 .473-2.036c.3-.733.662-1.433.979-2.154a5.5 5.5 0 0 0 .457-1.422 1.7 1.7 0 0 0 0-.6.538.538 0 0 0-.8-.366 2.066 2.066 0 0 0-.624.571 2.953 2.953 0 0 0-.177.264c-.14.221-.134.226-.387.14s-.264-.092-.129-.329a3.022 3.022 0 0 1 2.743-1.654A1.615 1.615 0 0 1 68.823 37a5.533 5.533 0 0 1-.484 2.09c-.3.754-.683 1.47-1.022 2.2a5.457 5.457 0 0 0-.484 1.4 1.511 1.511 0 0 0 0 .6.538.538 0 0 0 .581.458 1.543 1.543 0 0 0 1.258-.684 4.018 4.018 0 0 0 .393-.592c.043-.075.081-.092.151-.054a2.182 2.182 0 0 0 .269.108c.1.032.1.075.054.156a3.576 3.576 0 0 1-3.136 2 1.615 1.615 0 0 1-1.8-1.767z"
        fill={color}
        transform="translate(-29.718 -16.297)"
      />
      <path d="M32 49.755s0-.054.032-.038 0 .038 0 .059z" fill={color} transform="translate(-14.785 -22.935)" />
    </g>
  </svg>
);

TheQuintLogo.propTypes = {
  width: number,
  height: number,
  color: string,
  position: string,
  member: object,
  isActivePlan: number,
  activePlanId: number,
};

export default TheQuintLogo;
