import React, { useEffect, useState } from "react";
import { string, array, func, object, number, bool } from "prop-types";
import { connect } from "react-redux";
import { Link } from "@quintype/components";
import get from "lodash/get";

import OneTapLogin from "../one-tap-login";
import NewNativeLogin from "../new-native-login";
import SettingsView from "../../molecules/settings-view";
import SecondaryMenu from "../../molecules/secondary-menu";
import AdBlockerPopup from "../../molecules/ad-blocker-popup";
import MobilePopupMenu from "../../molecules/mobile-popup-menu";
import TestPopUp from "../../molecules/testing-popup";
import LinkView from "../../atoms/link-view";
import FitLogo from "../../atoms/icons/fit-logo";
import FitHindiColorLogo from "../../atoms/icons/fit-hindi";
import FitHindiLogo from "../../atoms/icons/fit-hindi-logo";
import PopupModal from "../../atoms/popup-modal";
import ToggleTheme from "../../atoms/toggle-theme";
import ProfileImage from "../../atoms/profile-image";
import SearchIcon from "../../atoms/icons/search-icon";
import FitColorLogo from "../../atoms/icons/fit-color-logo";
import TheQuintLogo from "../../atoms/icons/the-quint-logo";
import QuintHindiLogo from "../../atoms/icons/quint-hindi-logo";
import HindiQuintLogo from "../../atoms/icons/hindi-quint-logo";
import DesktopProfileIcon from "../../atoms/icons/user-profile-icon/desktop-profile-icon";
import MobileProfileIcon from "../../atoms/icons/user-profile-icon/mobile-profile-icon";
import { OutOfPageAd1, OutOfPageAd2, VidoomyAd, McanvasAd } from "../ad-free";

import translate from "../../translate";
import { getUser, getMemberSubscriptionInfo, getDiscountDetails, saveNewsletter } from "../../helper/api";
import { setMember, setUserPlans, setLoginWindow, setToogleTheme, setSubscriptionPlans } from "../../helper/actions";
import { getDefaultTheme } from "../../helper/localstorage";
// import { getElectionData } from "../../helper/firebase";
import {
  moengageTracking,
  getMobileNumber,
  isMobileNoPresent,
  showEmptyHeader,
  checkIfAlreadyMemeber,
  isBlackListerStory,
} from "../../utils/utils";
import { addGTM4PageVariables, userDetailsGTM } from "../../utils/story-and-user-details";
import { addSignUpLoginToDL } from "../../helper/datalayer-utils";
import { loadAccessType, setAccessTypeUser, getMyPlans, getAllPlans } from "../../helper/access-type";
import NewsLetterIcon from "../../atoms/icons/news-letter-widget/newsletter-icon";
import { eraseCookie, readCookie } from "../../helper/cookies";
import GiftView from "../../molecules/gift-view";

import "./header.m.css";

const Header = ({
  topMenuItems,
  secondaryMenuItems,
  primaryMenuItemsFit,
  secondaryMenuItemsFit,
  hamburgerMenuItems,
  pageType,
  currentPath,
  publisherAttributes,
  member,
  isActivePlan,
  activePlanId,
  setUserPlans,
  setMember,
  navigateToPage,
  currentHostUrl,
  googleClientId,
  accountKey,
  env,
  setLoginWindow,
  showLoginWindow,
  setToogleTheme,
  giftMembership,
  couponActive,
  setSubscriptionPlans,
}) => {
  const [isSettingsPopupViewOpen, setIsSettingsPopupViewOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [coupondetails, setCouponDetails] = useState("");
  const [showAdBlockerPopup, setShowAdBlockerPopup] = useState(false);
  const [showMembershipGift, setShowMembershipGift] = useState(false);
  const blackListVariable = isBlackListerStory(currentPath);
  const [popUpVisible, setPopUpVisible] = useState();

  useEffect(() => {
    if (global.AccessType) {
      getAllPlans().then((plans) => {
        // console.log("Subscription plans", plans);
        setSubscriptionPlans(plans);
      });
    }
  }, []);
  useEffect(() => {
    global.AccessType ? setUpUserFlow() : loadAccessType(accountKey, env, setUpUserFlow);
    addGTM4PageVariables(null, "WEB", null, pageType);
    setShowAlert(isMobileNoPresent(member) || pageType === "dashboard-page");
  }, [pageType]);

  const isHomeMenuRequired = true;
  const showOutOfPageAd = true;

  const getTopNavBarMenuItems = () => {
    if (currentPath.split("/")[1] === "fit") {
      return primaryMenuItemsFit.length ? primaryMenuItemsFit : [];
    } else {
      return topMenuItems.length ? topMenuItems : [];
    }
  };

  const getSecondNavBarMenuItems = () => {
    if (currentPath.split("/")[1] === "fit") {
      return secondaryMenuItemsFit.length ? secondaryMenuItemsFit : [];
    } else {
      return secondaryMenuItems.length ? secondaryMenuItems : [];
    }
  };

  const topNavBarMenuItems = getTopNavBarMenuItems();
  const secondNavBarMenuItems = getSecondNavBarMenuItems();
  const publisherName = publisherAttributes.name;

  useEffect(() => {
    if (member) {
      getUser()
        .then((res) => {
          const userInfo = res.user;
          setupUserPlans(userInfo);
        })
        .catch((ex) => {
          const scriptFlag = "__googleOneTapScript__";
          if (window[scriptFlag]) {
            window.google.accounts.id.prompt();
          }
        });
    }
  }, [member]);

  useEffect(() => {
    setShowAlert(isMobileNoPresent(member) || pageType === "dashboard-page");
  }, [coupondetails]);

  useEffect(() => {
    if (couponActive) {
      getDiscountDetails().then((response) => {
        const content = get(response, ["static-page", "content"], "");
        if (content) {
          const { coupon } = JSON.parse(content);
          const activeCoupon = coupon.find((i) => i.active);
          if (activeCoupon && !isActivePlan) {
            setCouponDetails(activeCoupon);
            const container = document.getElementById("container");
            if (container) {
              let newMarginTop = 0;
              const desktopMargin = parseInt(activeCoupon["desktop-margin"]) || 25;
              const mobileMargin = parseInt(activeCoupon["mobile-margin"]) || 25;
              if (window.innerWidth > 768) {
                newMarginTop = 180 + desktopMargin;
              } else {
                newMarginTop = 150 + mobileMargin;
              }
              container.style.cssText = `margin-top: ${newMarginTop}px !important;`;
            }
          } else setCouponDetails("");
        }
      });
    }
  }, [isActivePlan, couponActive]);

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("access-token")) {
        getUser().then((response) => {
          const { user } = response;
          setMember(user);
          console.log("socialLogin member:>:>:>:>:>:>:>:>", user);
          moengageTracking("registration", user);
          const checkMember = checkIfAlreadyMemeber(user.email, user.name).then((response) => {
            return response;
          });
          if (checkMember === "Member already exists") {
            addSignUpLoginToDL("loginInfo", user);
          } else {
            addSignUpLoginToDL("signUpInfo", user);
          }
          params.delete("access-token");
        });
      }
    }
    const defaultTheme = getDefaultTheme();
    if (defaultTheme === null || defaultTheme === "system") {
      const isDarkSystemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setToogleTheme(isDarkSystemTheme, true);
    }
  }, []);

  useEffect(() => {
    const adDiv = document.createElement("div");
    adDiv.className = "textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox";
    document.body.appendChild(adDiv);
    setTimeout(() => {
      const computedStyle = window.getComputedStyle(adDiv);
      if (isActivePlan === 0) {
        if (pageType === "home-page" || pageType === "story-page" || pageType === "section-page") {
          if (computedStyle.display === "none" || computedStyle.visibility === "hidden") {
            setShowAdBlockerPopup(true);
          } else {
            setShowAdBlockerPopup(false);
          }
        } else {
          setShowAdBlockerPopup(false);
        }
      } else {
        setShowAdBlockerPopup(false);
      }
    }, 6000);
  }, [isActivePlan, member, pageType]);

  const handleBannerClick = (e) => {
    e.preventDefault();
    navigateToPage(
      `/plan-selection?utm_source=top_menu&utm_medium=insider_button&banner_click=${coupondetails["coupon-code"]}`
    );
    const captureViewBannerDL = (coupon) => {
      if (window && window.dataLayer) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "view_promotion",
          current_url: document.title,
          page_path: window.location.href,
          ecommerce: {
            creative_name: coupon["coupon-name"],
            creative_slot: "membershipCoupon",
            promotion_id: coupon["coupon-name"],
            promotion_name: coupon["coupon-code"],
            coupon_percentage: coupon.discount[0],
            items: [
              {
                item_id: "planId",
                item_name: "planName",
                affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
                coupon: coupon["coupon-code"],
                discount: coupon.discount[0],
                index: 0,
                item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
                item_category: "Membership Plan",
                item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
                item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
                item_variant: "membership plan",
                location_id: window.location.href,
                price: "totalPrice",
                quantity: 1,
              },
            ],
          },
        });
      }
    };

    captureViewBannerDL(coupondetails);
  };

  const setUpUserFlow = () => {
    getUser()
      .then((res) => {
        const userInfo = res.user;
        setMember(userInfo);
        setShowAlert(isMobileNoPresent(userInfo) || pageType === "dashboard-page");
        setupUserPlans(userInfo, "hit");
      })
      .catch((ex) => {
        const scriptFlag = "__googleOneTapScript__";
        if (window[scriptFlag]) {
          window.google.accounts.id.prompt();
        }
        userDetailsGTM(null);
      });
  };

  const setupPlans = (user, event) => {
    getMyPlans()
      .then((plans) => {
        setUserPlans(plans);
        event === "hit" && userDetailsGTM(user, plans);
      })
      .catch((e) => console.log("getMyPlans error :>:>", e));
  };

  const setupUserPlans = (user, event) => {
    if (global.AccessType && user) {
      const mobileNumber = getMobileNumber(user);
      const name = user["first-name"] || user.name;
      setAccessTypeUser(user.email, mobileNumber, user.id, name).then(() => {
        setupPlans(user, event || "");
        if (readCookie("apple-signup-with-newsletter") === "subscribed") {
          const url = window.location.href;
          const body = {
            email: user.email,
            subscriptionStatus: "Subscribed",
            referredUrl: url,
            publisher: publisherName,
          };
          saveNewsletter(body);
          eraseCookie("apple-signup-with-newsletter");
        }
      });
    } else {
      getMemberSubscriptionInfo()
        .then((res) => setUserPlans(res))
        .catch((e) => console.log("getMemberSubscriptionInfo error :>:>"));
    }
  };

  const onHamburgerClick = () => {
    document.getElementById("mySidenav").style.left = "-100%";
    document.body.style.overflowY = "auto";
  };

  const closeLoginWindow = () => setLoginWindow(false);

  const onLoginClick = () => {
    setLoginWindow(true, "top_menu");
  };

  const onPopupSettingsClick = () => {
    setIsSettingsPopupViewOpen(!isSettingsPopupViewOpen);

    onHamburgerClick();
  };
  const oncloseGiftMembershipPopup = () => {
    setShowMembershipGift(false);
  };

  const onShowGiftMessageClick = () => {
    setIsSettingsPopupViewOpen(!isSettingsPopupViewOpen);
    setShowMembershipGift(!showMembershipGift);
    window.dataLayer.push({
      event: "gm_message_from_gifter_click",
      member: "from Membership",
    });
  };

  const loginView = (type) => {
    if (member) {
      return <ProfileImage member={member} onClick={onPopupSettingsClick} giftMembership={giftMembership} />;
    }

    const profileIcon = type === "desktop" ? <DesktopProfileIcon /> : <MobileProfileIcon />;
    const buttonId = "profile-button-icon-" + type;

    return (
      <button id={buttonId} styleName="signin" buttontype="type1" onClick={onLoginClick} aria-label="profile icon">
        <div styleName="profile-circle" />
        <div styleName="user-profile-icon">{profileIcon}</div>
      </button>
    );
  };

  const getButtonsView = () => {
    const handleClick = () => {
      window.localStorage.setItem("sym_initiate_page", currentPath || "/");
      window.localStorage.setItem("sym_initiate_position", "ATF - Header");
    };

    const getPlanSelectionUrlWithUtms = () => {
      const planSelectionUrl = "/plan-selection?utm_source=top_menu&utm_medium=insider_button";
      return planSelectionUrl;
    };

    const isBlacklisted = isBlackListerStory(currentPath);

    if (showEmptyHeader(pageType)) {
      return (
        <Link
          href={getPlanSelectionUrlWithUtms()}
          styleName="support-subscribe-btn"
          className="header-support-the-quint"
          aria-label={translate(publisherName, "header-cta")}
        >
          {!isActivePlan && !isBlacklisted && (
            <div id="become-member-button" styleName="become-member" onClick={handleClick}>
              {translate(publisherName, "header-cta")}
            </div>
          )}
        </Link>
      );
    }
  };

  // Login Popup Modal View
  const loginPopupView = () => {
    if (!showLoginWindow) {
      return null;
    }

    return (
      <PopupModal closePopup={closeLoginWindow}>
        <NewNativeLogin closePopup={closeLoginWindow} setupPlans={setupUserPlans} />
      </PopupModal>
    );
  };

  // Settings Popup Modal View
  const settingsPopupView = () => {
    if (!isSettingsPopupViewOpen) {
      return null;
    }

    return (
      <PopupModal closePopup={onPopupSettingsClick}>
        <SettingsView
          closePopup={onPopupSettingsClick}
          navigateToPage={navigateToPage}
          showAlert={!showAlert}
          showGiftMessage={onShowGiftMessageClick}
        />
      </PopupModal>
    );
  };

  const giftMessagePopupView = () => {
    if (!showMembershipGift) {
      return null;
    }
    return (
      <PopupModal closePopup={oncloseGiftMembershipPopup}>
        <GiftView closePopup={oncloseGiftMembershipPopup} giftMembership={giftMembership} />
      </PopupModal>
    );
  };
  // Logs for English Quint
  const getQuintLogoView = () => (
    <React.Fragment>
      <div styleName="vertical-line"></div>
      <div styleName="logos-wrapper">
        <LinkView
          className="header-hq-logo opacity-logo"
          link="https://hindi.thequint.com/"
          ariaLabel="The Quint Hindi homepage"
        >
          <QuintHindiLogo member={member} isActivePlan={isActivePlan} activePlanId={activePlanId} />
        </LinkView>
        <Link href="/fit" className="opacity-logo" aria-label="Fit homepage">
          <div styleName="fit-logo-wrapper">
            <FitLogo member={member} />
          </div>
        </Link>
      </div>
    </React.Fragment>
  );

  // Logs for Quint-Hindi
  const getHindiLogoView = () => (
    <React.Fragment>
      <div styleName="vertical-line"></div>
      <div styleName="logos-wrapper">
        <LinkView
          className="english-quint-desktop-view"
          link="https://www.thequint.com/"
          ariaLabel="The Quint homepage"
        >
          <TheQuintLogo width={75} height={42} color="#fff" member={member} />
        </LinkView>
        <Link href="/fit" className="opacity-logo" aria-label="Fit homepage">
          <div styleName="fit-logo-wrapper">
            <FitHindiLogo width="80" height="50" member={member} />
          </div>
        </Link>
      </div>
    </React.Fragment>
  );

  // Mobile Logo View
  const getMobileLogoView = () => {
    if (publisherName === "quint-hindi" && currentPath.split("/")[1] === "fit") {
      return (
        <div styleName="fit-header-logo-wrapper">
          <div className="header-hq-logo" styleName="hindi-color-logo hindi-color-fit-logo">
            <Link href="/" aria-label="Quint hindi homepage">
              <QuintHindiLogo width={80} height={46} member={member} color="var(--twentythree-color)" />
            </Link>
          </div>
          <div styleName="separator"></div>
          <div styleName="fit-hindi-color-logo">
            <Link href="/fit" aria-label="Fit homepage">
              <FitHindiColorLogo width={50} height={46} />
            </Link>
          </div>
        </div>
      );
    } else if (publisherName === "quint-hindi" && currentPath.includes("/plan-selection")) {
      return (
        <div id="header-hq-logo-memebership-page" styleName="hindi-color-logo">
          <Link href="/" aria-label="The Quint Hindi homepage">
            <QuintHindiLogo width={130} height={46} member={member} />
          </Link>
        </div>
      );
    } else if (publisherName === "quint-hindi") {
      return (
        <div className="header-hq-logo" styleName="hindi-color-logo">
          <Link href="/" aria-label="Quint Hindi homepage">
            <QuintHindiLogo width={130} height={46} member={member} color="var(--twentythree-color)" />
          </Link>
        </div>
      );
    } else if (publisherName === "quint" && currentPath.split("/")[1] === "fit") {
      return (
        <div styleName="fit-color-logo">
          <div styleName="fit-quint-logo">
            <Link href="/" aria-label="The Quint homepage">
              <TheQuintLogo width={43} height={46} color="#35144f" member={member} />
            </Link>
          </div>
          <div styleName="separator"></div>
          <Link href="/fit" aria-label="Fit homepage">
            <FitColorLogo width={43} member={member} />
          </Link>
        </div>
      );
    }

    return (
      <div styleName={showEmptyHeader(pageType) ? "engish-quint-logo" : "membership-page-quint-logo"}>
        <Link href="/" aria-label="The Quint homepage">
          <TheQuintLogo width={74} height={46} color="#fff" member={member} />
        </Link>
      </div>
    );
  };

  // Desktop Logo View
  const getDesktopLogoView = () => {
    // fit Desktop Logo View
    if (publisherName === "quint" && currentPath.split("/")[1] === "fit") {
      return (
        <React.Fragment>
          <Link href="/" styleName="header-tq-logo" aria-label="The Quint homepage">
            <TheQuintLogo width={75} height={50} color="#fff" member={member} />
          </Link>

          <div styleName="vertical-line"></div>

          <div styleName="fit-desktop-view logos-wrapper">
            <Link href="/fit" styleName="quint-logo" aria-label="Fit homepage">
              <FitColorLogo width={100} height={60} member={member} />
            </Link>
            <div styleName="reduce-opacity">
              <LinkView
                className="header-hq-logo"
                link="https://hindi.thequint.com/"
                ariaLabel="The Quint Hindi homepage"
              >
                <QuintHindiLogo member={member} />
              </LinkView>
            </div>
          </div>
        </React.Fragment>
      );
    }

    const getFitHindiLogoView = () => (
      <React.Fragment>
        <div styleName="vertical-line"></div>
        <div styleName="logos-wrapper">
          <Link href="/fit" styleName="quint-logo fit-hindi-desktop-logo" aria-label="Fit homepage">
            <FitHindiColorLogo />
          </Link>
          <LinkView
            className="english-quint-desktop-view"
            link="https://www.thequint.com/"
            ariaLabel="The Quint homepage"
          >
            <TheQuintLogo width={75} height={42} color="#fff" member={member} />
          </LinkView>
        </div>
      </React.Fragment>
    );

    // Hindi Quint Desktop Logo View
    if (publisherName === "quint-hindi" && currentPath.split("/")[1] === "fit") {
      return (
        <React.Fragment>
          <Link className="header-hq-logo opacity-logo" href="/" aria-label="Quint Hindi homepage">
            <QuintHindiLogo member={member} isActivePlan={isActivePlan} activePlanId={activePlanId} />
          </Link>
          {getFitHindiLogoView()}
        </React.Fragment>
      );
    }
    if (publisherName === "quint-hindi") {
      return (
        <React.Fragment>
          <Link href="/" styleName="quint-logo" aria-label="Quint Hindi homepage">
            <HindiQuintLogo member={member} />
          </Link>
          {showEmptyHeader(pageType) && getHindiLogoView()}
        </React.Fragment>
      );
    }

    // Quint Desktop Logo View
    return (
      <React.Fragment>
        <Link href="/" styleName="quint-logo" aria-label="The Quint homepage">
          <TheQuintLogo
            width={95}
            height={60}
            member={member}
            isActivePlan={isActivePlan}
            activePlanId={activePlanId}
          />
        </Link>

        {showEmptyHeader(pageType) && getQuintLogoView()}
      </React.Fragment>
    );
  };

  const discountBannerView = () => {
    const isBlacklisted = isBlackListerStory(currentPath);
    return coupondetails && coupondetails.active && !isActivePlan && !isBlacklisted ? (
      <div
        styleName="offer-banner"
        style={{
          backgroundColor: coupondetails["banner-background-color"] ? coupondetails["banner-background-color"] : "#fff",
          backgroundImage: coupondetails["banner-background-image"] ? coupondetails["banner-background-image"] : null,
        }}
      >
        <div>
          <a onClick={handleBannerClick}>
            <p style={{ color: coupondetails["banner-text-color"] ? coupondetails["banner-text-color"] : "#000" }}>
              {publisherName === "quint-hindi" ? coupondetails["banner-text-hindi"] : coupondetails["banner-text"]}
            </p>
          </a>
        </div>
      </div>
    ) : (
      ""
    );
  };

  return (
    <div styleName={showEmptyHeader(pageType) ? "header-wrapper" : "membership-page-header-wrapper"}>
      {/* Primary Header */}
      {popUpVisible && <TestPopUp popUpVisible={popUpVisible} setPopUpVisible={setPopUpVisible} />}
      {showOutOfPageAd ? (
        <>
          <div id="OutOfPageAd1" styleName="out-of-page-ad-wrapper">
            <OutOfPageAd1 />
          </div>
          <div id="OutOfPageAd2" styleName="out-of-page-ad-wrapper">
            <OutOfPageAd2 />
          </div>
        </>
      ) : null}
      <div styleName="out-of-page-ad-wrapper">
        <McanvasAd />
      </div>
      {publisherName === "quint" ? (
        <>
          <div styleName="out-of-page-ad-wrapper" id="VidoomyAd">
            <VidoomyAd />
          </div>
          {!blackListVariable && <OneTapLogin clientId={googleClientId} setMember={setMember} disable={member} />}
        </>
      ) : null}

      {discountBannerView()}

      <div styleName={showEmptyHeader(pageType) ? "primary-wrapper" : "primary-wrapper membership-page"}>
        {/* Desktop - Content */}
        <div styleName="left-content">{getDesktopLogoView()}</div>

        {/* Desktop - Content */}
        <div styleName="right-content" id="desktop-right-content">
          {getButtonsView()}
          {!isBlackListerStory(currentPath) && (
            <>
              <Link href="/subscribe" styleName="newsletter-icon" aria-label="News letter">
                <NewsLetterIcon />
              </Link>
              {loginView("desktop")}
              <Link href="/search" className="header-search-icon" styleName="search-desktop-icon" aria-label="Search">
                <SearchIcon />
              </Link>
            </>
          )}

          {showEmptyHeader(pageType) && <ToggleTheme type="computer" />}
        </div>

        {showAdBlockerPopup ? <AdBlockerPopup /> : null}

        {/* Mobile - Content */}
        <div styleName="mobile-content">
          <div styleName={publisherName === "quint-hindi" ? "mobile-logo mobile-logo-hindi" : "mobile-logo"}>
            {getMobileLogoView()}
          </div>
          <div styleName="right-side-block" id="mobile-right-content">
            {getButtonsView()}
            {!isBlackListerStory(currentPath) && (
              <>
                <Link href="/subscribe" styleName="newsletter-icon" aria-label="News letter">
                  <NewsLetterIcon width="40" height="40" />
                </Link>
                {loginView("mobile")}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Secondary Header Starts */}
      {/* Top Nav Bar */}
      {showEmptyHeader(pageType) && (
        <SecondaryMenu
          items={topNavBarMenuItems}
          pageType={pageType}
          publisherName={publisherName}
          isHomeMenuRequired={isHomeMenuRequired}
          currentHostUrl={currentHostUrl}
          firstMenuItemsLength={9}
          showHamburgerIcon={true}
          id="top-nav-bar"
          navbarNumber={1}
        />
      )}
      {/* Second Nav Bar */}
      {showEmptyHeader(pageType) && (
        <SecondaryMenu
          items={secondNavBarMenuItems}
          pageType={pageType}
          publisherName={publisherName}
          isHomeMenuRequired={isHomeMenuRequired}
          currentHostUrl={currentHostUrl}
          firstMenuItemsLength={8}
          showHamburgerIcon={false}
          id="second-nav-bar"
          navbarNumber={2}
        />
      )}
      {/* Secondary Header Ends */}
      {/* Mobile Overlay Popup */}
      <div id="mySidenav" styleName="mobile-popup-overlay">
        <MobilePopupMenu
          hamburgerMenuItems={hamburgerMenuItems}
          secondaryMenuItems={secondaryMenuItems}
          currentHostUrl={currentHostUrl}
          pageType={pageType}
          closeClick={onHamburgerClick}
          navigateToPage={navigateToPage}
          publisherName={publisherName}
          member={member}
          isActivePlan={isActivePlan}
          onPopupSettingsClick={onPopupSettingsClick}
          currentPath={currentPath}
        />
      </div>
      {/* Popup Modal's */}
      {loginPopupView()}
      {settingsPopupView()}
      {giftMessagePopupView()}
    </div>
  );
};

Header.propTypes = {
  topMenuItems: array,
  cmsMenuItems: array,
  secondaryMenuItems: array,
  hamburgerMenuItems: array,
  primaryMenuItemsFit: array,
  secondaryMenuItemsFit: array,
  pageType: string,
  publisherAttributes: object,
  navigateToPage: func,
  setMember: func,
  setUserPlans: func,
  member: object,
  isActivePlan: number,
  activePlanId: number,
  currentPath: string,
  currentHostUrl: string,
  googleClientId: string,
  accountKey: string,
  env: string,
  setLoginWindow: func,
  showLoginWindow: bool,
  setToogleTheme: func,
  giftMembership: object,
  couponActive: bool,
  setSubscriptionPlans: func,
};

const mapStateToProps = (state) => {
  return {
    topMenuItems: get(state, ["qt", "data", "navigationMenu", "primaryMenuItems"], []),
    secondaryMenuItems: get(state, ["qt", "data", "navigationMenu", "secondaryMenuItems"], []),
    primaryMenuItemsFit: get(state, ["qt", "data", "navigationMenu", "primaryMenuItemsFit"], []),
    secondaryMenuItemsFit: get(state, ["qt", "data", "navigationMenu", "secondaryMenuItemsFit"], []),
    hamburgerMenuItems: get(state, ["qt", "data", "navigationMenu", "hamburgerMenuItems"], []),
    pageType: get(state, ["qt", "pageType"], ""),
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"]),
    googleClientId: get(state, ["qt", "config", "publisher-attributes", "socialAuth", "google"], ""),
    currentHostUrl: get(state, ["qt", "currentHostUrl"], ""),
    currentPath: get(state, ["qt", "currentPath"], ""),
    showLoginWindow: get(state, ["userReducer", "showLoginWindow"], false),
    member: state.member,
    isActivePlan: get(state, ["userReducer", "isActivePlan"]),
    activePlanId: get(state, ["userReducer", "activePlanId"], null),
    giftMembership: get(state, ["userReducer", "giftMembership"], null),
    accountKey: get(state, ["qt", "config", "publisher-attributes", "accesstype", "account_key"]),
    env: get(state, ["qt", "config", "publisher-attributes", "env"], "production"),
    couponActive: get(state, ["qt", "config", "publisher-attributes", "coupon_active"], false),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserPlans: (plans) => dispatch(setUserPlans(plans)),
  navigateToPage: (url) => global.app.navigateToPage(dispatch, url),
  setMember: (member) => dispatch(setMember(member)),
  setLoginWindow: (value, logInFrom) => dispatch(setLoginWindow(value, logInFrom)),
  setToogleTheme: (value) => dispatch(setToogleTheme(value)),
  setSubscriptionPlans: (value) => dispatch(setSubscriptionPlans(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
