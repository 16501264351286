import React from "react";
import { object, number, bool, func, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

// import { captureTopNavigationClick, captureBottomNavigationClick } from "../../helper/datalayer-utils";
import LinkView from "../link-view";
// import Whatsapp from "../../atoms/icons/whatsapp";
// import PregaNewsIcon from "../../atoms/icons/prega-news-icon";

import "./menuItem.m.css";

const MenuItem = ({
  member,
  isActivePlan,
  activePlanId,
  item,
  isPopup,
  closePopup,
  navigateToPage,
  currentHostUrl,
  menuPosition,
  id,
  isDarkTheme,
}) => {
  // const captureDLNavigationClick = (title) => {
  //   if (menuPosition && menuPosition === "footer") {
  //     setTimeout(
  //       captureBottomNavigationClick(
  //         title,
  //         member && member,
  //         isActivePlan && isActivePlan,
  //         activePlanId && activePlanId
  //       ),
  //       1000
  //     );
  //   } else {
  //     setTimeout(
  //       captureTopNavigationClick(title, member && member, isActivePlan && isActivePlan, activePlanId && activePlanId),
  //       1000
  //     );
  //   }
  // };

  const onMenuClick = (url, title) => (e) => {
    e.preventDefault();

    // captureDLNavigationClick(title);
    closePopup();
    navigateToPage(url);
  };

  const getView = (url) => {
    if (isPopup) {
      return (
        <div id={id} styleName="wrapper-link" onClick={onMenuClick(url, item.title)}>
          {item.title}
        </div>
      );
    }
    url =
      item["menu-group-slug"].includes("fit") && item.completeUrl.includes("hindi") && item["item-type"] === "section"
        ? `/fit/${item["section-slug"]}`
        : url;

    return (
      <a id={id} href={url}>
        <div
          style={{
            color: item.data["icon-code"]
              ? isDarkTheme
                ? item.data["icon-code"].split(",")[2]
                : item.data["icon-code"].split(",")[1]
              : "",
            fontWeight: item.data["icon-code"] && item.data["icon-code"].length && item.data["icon-code"].split(",")[0],
          }} /* onClick={() => captureDLNavigationClick(item.title)} */
        >
          {item.title}
        </div>
      </a>
    );
  };

  const getLinkView = () => {
    const isExternal = !item.completeUrl.includes(currentHostUrl);
    const completeUrl =
      item["menu-group-slug"].includes("fit") && item.completeUrl.includes("hindi") && item["item-type"] === "section"
        ? `/fit/${item["section-slug"]}`
        : item.completeUrl;

    return (
      <LinkView id={id} link={completeUrl} isExternal={isExternal}>
        <div /* onClick={() => captureDLNavigationClick(item.title)} */
          style={{
            color: item.data["icon-code"]
              ? isDarkTheme
                ? item.data["icon-code"].split(",")[2]
                : item.data["icon-code"].split(",")[1]
              : "",
            fontWeight: item.data["icon-code"] && item.data["icon-code"].length && item.data["icon-code"].split(",")[0],
          }}
        >
          {item.title}
        </div>
      </LinkView>
    );
  };

  return item.isExternalLink ? getLinkView() : getView(item.completeUrl);
};

MenuItem.defaultProps = {
  isPopup: false,
  menuPosition: "header",
  isDarkTheme: false,
};

MenuItem.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  item: object,
  isPopup: bool,
  closePopup: func,
  navigateToPage: func,
  currentHostUrl: string,
  menuPosition: string,
  id: string,
  isDarkTheme: bool,
};

const mapStateToProps = (state) => {
  return {
    member: state.member,
    isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
    activePlanId: get(state, ["userReducer", "activePlanId"], null),
    isDarkTheme: get(state, ["settingsReducer", "isDarkTheme"], false),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
  };
};

export default connect(mapStateToProps, null)(MenuItem);
