/* eslint-disable max-len */
import React from "react";
import { number, string, object } from "prop-types";

// import { captureTopNavigationClick, captureBottomNavigationClick } from "../../../helper/datalayer-utils";

const FitColorLogo = ({ width = 75, height = 42, position, member, isActivePlan, activePlanId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="fit-icon"
    width={width}
    height={height}
    viewBox="0 0 75 42"
    // onClick={() => {
    //   position === "footer"
    //     ? setTimeout(captureBottomNavigationClick("Fit", member && member), 1000)
    //     : setTimeout(
    //         captureTopNavigationClick(
    //           "Fit",
    //           member && member,
    //           isActivePlan && isActivePlan,
    //           activePlanId && activePlanId
    //         ),
    //         1000
    //       );
    // }}
  >
    <path d="M0 0H75V42H0z" fill="transparent" />
    <g transform="translate(3 3.201)">
      <g transform="translate(29.796 3.798)">
        <path
          d="M972.93 1406.9v4.987h8.192a.8.8 0 0 1 .91.86v3.574a.8.8 0 0 1-.91.86h-8.192v7.639a.8.8 0 0 1-.91.86h-4.01a.8.8 0 0 1-.91-.86v-22.36a.8.8 0 0 1 .91-.86h13.29a.8.8 0 0 1 .91.86v3.573a.8.8 0 0 1-.91.861h-8.37z"
          fill="#fff"
          transform="translate(-967.1 -1401.6)"
        />
        <path
          d="M1125.667 1401.661a1.2 1.2 0 0 0-.407-.061h-1.109a1.3 1.3 0 0 0-.407.061.765.765 0 0 0-.543.8v22.365c0 .553.339.86.95.86h1.109c.611 0 .951-.307.951-.86v-22.366a.766.766 0 0 0-.544-.799z"
          fill="#fff"
          transform="translate(-1106.272 -1401.6)"
        />
        <path
          d="M1182.368 1402.46v3.575a.8.8 0 0 1-.91.861h-4.909v17.929a.8.8 0 0 1-.91.86h-4.009a.8.8 0 0 1-.91-.86V1406.9h-4.909a.8.8 0 0 1-.91-.861v-3.575a.8.8 0 0 1 .91-.861h15.648a.811.811 0 0 1 .909.857z"
          fill="#fff"
          transform="translate(-1143.13 -1401.6)"
        />
      </g>
      <path
        d="M694.665 1400.624l-11.406-13.171s-4.71-.088-6.491-.195c-4.868-.3-6.892-3.421-6.743-7.764 0 0 .037-6.142.214-9.014.252-4.236 2.453-5.725 6.92-5.469a86.931 86.931 0 0 1 9.932 1.25c2.779.559 4.663 1.8 5.642 5.389.485 1.773 1.492 6.984 1.94 9.307.494 2.606.3 5.379-3.432 6.027z"
        transform="translate(-670.018 -1364.984)"
        fill="#00c5c5"
      />
    </g>
  </svg>
);

FitColorLogo.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  width: number,
  position: string,
  height: number,
};

export default FitColorLogo;
