import React, { useEffect } from "react";
import { object, number, bool, func, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { setToogleTheme } from "../../helper/actions";
import { getTheme, setDefaultTheme } from "../../helper/localstorage";
// import { captureTopNavigationClick } from "../../helper/datalayer-utils";
import translate from "../../translate";

import "./toggle-theme.m.css";

const ToggleTheme = ({
  member,
  isActivePlan,
  activePlanId,
  isDarkTheme,
  setToogleTheme,
  publisherName,
  currentPath,
  type,
}) => {
  // This useEffect is called only once to get the theme from localstorage
  useEffect(() => {
    setToogleTheme(getTheme(), true);
  }, []);

  /**
   * This useEffect will be called in 2 scenarios
   * 1. When user toggle's the theme
   * 2. When above useEffect updates the isDarkTheme in redux store
   */
  useEffect(() => {
    if (currentPath.split("/")[1] === "fit") {
      document.documentElement.setAttribute("theme", `fit-${isDarkTheme ? "dark" : "white"}`);
    } else {
      document.documentElement.setAttribute("theme", `quint-${isDarkTheme ? "dark" : "white"}`);
    }
  }, [isDarkTheme, currentPath]);

  const onToggleThemeChange = (e) => {
    if (e.target.checked) {
      setDefaultTheme("dark");
    } else {
      setDefaultTheme("light");
    }
    setToogleTheme(e.target.checked);
    // setTimeout(
    //   captureTopNavigationClick(
    //     "darkmode toggle",
    //     member && member,
    //     isActivePlan && isActivePlan,
    //     activePlanId && activePlanId
    //   ),
    //   1000
    // );
  };

  return (
    <div styleName="toggle-theme" className="toggle-theme">
      <label styleName="switch" htmlFor={"toggle-theme-input-" + type}>
        <input
          id={"toggle-theme-input-" + type}
          type="checkbox"
          styleName="switch-input"
          checked={isDarkTheme}
          onChange={onToggleThemeChange}
          aria-label={isDarkTheme ? "dark-pressed" : "light-mode"}
        />
        <span styleName="slider" className="toggle-theme-slider" />
      </label>
      <span styleName="text" className="toggle-theme-text">
        {translate(publisherName, "dark_mode")}
      </span>
    </div>
  );
};

ToggleTheme.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  isDarkTheme: bool,
  setToogleTheme: func,
  publisherName: string,
  currentPath: string,
  type: string,
};

const mapStateToProps = (state) => {
  return {
    member: state.member,
    isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
    activePlanId: get(state, ["userReducer", "activePlanId"], null),
    isDarkTheme: get(state, ["settingsReducer", "isDarkTheme"], false),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setToogleTheme: (flag) => dispatch(setToogleTheme(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleTheme);
