/* eslint-disable max-len */
import { getMobileNumber } from "../utils/utils";

const FIELDS =
  "id,headline,slug,url,hero-image-s3-key,hero-image-caption,seo,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,summary,authors,read-time,author-name,author-id,sections,story-template,cards,metadata,contributors,linked-story-ids,linked-stories,access";

const storyPageSponsoredItem = () => {
  return global
    .wretch("/api/v1/collections/sponsored-infinite-scrolls")
    .query({
      limit: 1,
    })
    .get()
    .json((response) => response.items);
};

export const storyPageLoadItems = (fitFlag = false, limit = 4, id) => {
  return global
    .wretch(fitFlag ? "/api/v1/collections/top-stories-fit" : "/api/v1/collections/top-story-home")
    .query({
      fields: FIELDS,
      limit: limit,
      offset: 0,
    })
    .get()
    .json((response) =>
      response.items
        .filter((i) => i.type === "story" && i.id !== id)
        .map((story) => ({ story: story.story, otherProp: "value" }))
    );
};

export const storyPageLoadPhotoStories = (limit = 4) => {
  return global
    .wretch("/api/v1/collections/photos")
    .query({
      fields: FIELDS,
      limit: limit,
      offset: 0,
    })
    .get()
    .json((response) =>
      response.items.filter((i) => i.type === "story").map((story) => ({ story: story.story, otherProp: "value" }))
    );
};

export const loadStoriesForInfiniteScroll = async () => {
  const [result1, result2] = await Promise.all([storyPageSponsoredItem(), storyPageLoadItems()]);
  const restStories = result2.filter((item) => {
    const story = item.story;
    const template = story["story-template"];
    return template !== "photo";
  });
  return [...result1, ...restStories];
};

export const loadStoriesForFitInfiniteScroll = async () => {
  const [result1, result2] = await Promise.all([storyPageSponsoredItem(), storyPageLoadItems(true)]);
  return [...result1, ...result2];
};

export const loadPhotoStoriesForInfiniteScroll = async () => {
  const result = await storyPageLoadPhotoStories();
  return result;
};

export const loadMoreCollectionItems = (slug, pageNumber) => {
  return global
    .wretch(`/api/v1/collections/${slug}`)
    .query({
      limit: 8,
      offset: pageNumber * 8,
    })
    .get()
    .json((response) => response.items.filter((i) => i.type === "story").map((story) => story.story));
};

export const loadMoreSpecialProjectCollectionItens = (slug, pageNumber) => {
  return global
    .wretch(`/api/v1/collections/${slug}`)
    .query({
      limit: 5,
      offset: pageNumber * 5,
    })
    .get()
    .json((response) => response.items.filter((i) => i.type === "story").map((story) => story.story));
};

// Get Advanced Search Stories
export function getAdvancedSearchStories(offset, itemsToLoad, queryParams) {
  const storyFields =
    "headline,subheadline,slug,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata";

  return global
    .wretch()
    .url("/api/v1/advanced-search")
    .query({
      fields: storyFields,
      offset: offset,
      limit: itemsToLoad,
      ...queryParams,
    })
    .get()
    .json((response) => response);
}

// Get Author Stories
export function getAuthorStories(authorId, offset, limit) {
  const storyFields =
    "headline,subheadline,slug,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata";

  return global
    .wretch()
    .url(`/api/v1/authors/${authorId}/collection`)
    .query({
      "story-fields": storyFields,
      "item-type": "story",
      offset: offset,
      limit: limit,
    })
    .get()
    .json((response) => (response.items || []).map((item) => item.story));
}

export const getDmVideo = (playListId) => {
  return global.fetch(`https://api.dailymotion.com/playlist/${playListId}/videos?fields=embed_html,id,title`);
};

export const submitNewsLetter = (id, token, body) => {
  return global
    .wretch()
    .url("/subscribe")
    .query({ id, token })
    .post(body)
    .text((res) => res);
};

export const getStory = (storyId) => {
  return global
    .wretch()
    .url(`/api/v1/stories/${storyId}`)
    .get()
    .json((response) => response.story);
};

export const relatedStory = (slug, id, offset = 0) => {
  return global
    .wretch()
    .url(`/api/v1/collections/${slug}?exclude-story-ids=${id}`)
    .query({ limit: 5, offset })
    .get()
    .json((response) => response.items.filter((i) => i.type === "story").map((storyItems) => storyItems.story));
};

export function getTotalCountOfEachCollection(slug, limit = 2) {
  return global
    .wretch()
    .url(`/api/v1/collections/${slug}`)
    .query({ limit })
    .get()
    .json((response) => response);
}

// Returns the current User
export function getUser() {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/me")
    .get()
    .json((res) => Promise.resolve(res))
    .catch((ex) => Promise.reject(ex));
}

export function updateUserInformation(updatedData) {
  return global
    .wretch()
    .url("/api/auth/v1/users/profile")
    .post(updatedData)
    .res((r) => console.log("updateUserInformation response", r))
    .catch((ex) => console.log("updateUserInformation Reject", ex));
}

// Logout the User
export function logout() {
  return global
    .wretch()
    .url("/api/logout")
    .get()
    .res(() => Promise.resolve())
    .catch((ex) => Promise.reject(ex));
}

export const sendTransactionalEmail = (options) => {
  return global
    .wretch()
    .url("/transactional-emails")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .res((res) => res);
};

export const sendTransactionalEmailQW = (options) => {
  return global
    .wretch()
    .url("/transactional-emails-qw")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .res((res) => res);
};

export const submitFeedbackData = (member, text) => {
  const mobileNumber = getMobileNumber(member);
  const options = {
    formInput: `Email=${member.email}&MultiLine=${text}&PhoneNumber_countrycode=${mobileNumber}`,
  };

  return global
    .wretch()
    .url("/cancel-subscription")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .res((res) => res);
};

export const getFitStories = (collectionSlug) => {
  return global
    .wretch()
    .url(`fit-stories`)
    .query({ limit: 6, slug: collectionSlug })
    .get()
    .json((response) => response);
};

export const getMemberSubscriptionInfo = () => {
  return global
    .wretch()
    .url(`${window.location.origin}/api/access/v1/members/me/subscriptions`)
    .query({ key: "EK8k5gPbU5pG1hV5UdrPUHbT" })
    .get()
    .json((response) => response);
};

export function generateJwtToken(email, id, name) {
  return global
    .wretch()
    .url("/generate-token")
    .post({ email, id, name })
    .json((result) => result.token)
    .catch((ex) => Promise.reject(ex));
}

export function validateUser(token, redirectURL) {
  return global
    .wretch()
    .url("/api/auth/v1/login/google-one-tap")
    .post({ token, "redirect-url": redirectURL })
    .json((member) => member)
    .catch((ex) => Promise.reject(ex));
}

export function loginUser(email, password) {
  return global
    .wretch()
    .url("/api/auth/v1/login")
    .headers({ "Content-Type": "application/json" })
    .post({
      username: email,
      password: password,
    })
    .json((member) => member)
    .catch((ex) => Promise.reject(ex));
}

export function signUpUser(options) {
  return global
    .wretch()
    .url("/api/auth/v1/signup")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .json((member) => member)
    .catch((ex) => Promise.reject(ex));
}

export function forgotPassword(email) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/forgot-password")
    .headers({ "Content-Type": "application/json" })
    .post({ email })
    .json((response) => response)
    .catch((ex) => Promise.reject(ex));
}

// Reset the password
export function resetPassword(token, password, repeatPassword) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/reset-password-with-token")
    .post({
      token: token,
      "new-password": password,
      "confirm-password": repeatPassword,
    })
    .json((response) => response)
    .catch((ex) => {
      if (ex.message) {
        const res = JSON.parse(ex.message);
        return Promise.reject(res.error);
      }

      return Promise.reject(ex);
    });
}

export function getCampaignSubscriptionGroups(key) {
  return global
    .wretch()
    .url(`https://www.accesstype.com/api/v1/campaigns.json`)
    .query({ key: key })
    .get()
    .json((response) => response);
}

export function getCashfreeOrderDetails(orderId) {
  return global
    .wretch()
    .url("/cashree-order-details")
    .query({ orderId })
    .get()
    .json((res) => {
      const data = JSON.parse(res.text);
      return data[0].payment_status;
    });
}

export function createCashfreeOrder(body) {
  return global
    .wretch()
    .url("/cashfree-create-order")
    .post(body)
    .json((response) => {
      const data = JSON.parse(response.text);
      return data.payment_session_id;
    });
}

export function getDiscountDetails() {
  return global
    .wretch()
    .url("https://www.thequint.com/api/v1/static-pages/coupons.json")
    .get()
    .json((response) => {
      return response;
    });
}

export function getSubsciptionDetails(key) {
  return global
    .wretch()
    .url("https://www.accesstype.com/api/v1/subscription_groups.json")
    .query({ key: key })
    .get()
    .json((response) => response);
}

export function storyBookmarking(body, id, token, msg) {
  return global
    .wretch()
    .url("/add-bookmark")
    .query({ id, token, msg })
    .post(body)
    .text((res) => res);
}

export const topicAvailable = (tag) => {
  return global
    .wretch()
    .url(`/api/v1/tags/${tag}`)
    .get()
    .json((response) => response);
};

export const getActiveSpecialProjectCollections = () => {
  return global
    .wretch()
    .url("https://www.thequint.com/api/v1/static-pages/special-projects-json")
    .get()
    .json((response) => response);
};

export const getCollectioBySlug = (slug) => {
  return global
    .wretch(`/api/v1/collections/${slug}`)
    .get()
    .json((response) => response);
};

export const saveNewsletter = (body) => {
  return global
    .wretch("/save-newsletter")
    .post(body)
    .json((response) => {
      const data = response;
      console.log("Google API response", data);
    });
};

export const checkExistingUser = (id) => {
  console.log(id);
  return global
    .wretch()
    .url(`/api/auth/v1/users/email/check?email=${id}`)
    .headers({ "Content-Type": "application/json" })
    .get()
    .json((res) => res);
};

export const getUserCookies = (baseUrl) => {
  return global
    .wretch(`${baseUrl}set-cookie?geo-tag=true`)
    .headers({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Credentials": "true", // Allow cookies to be sent
    })
    .get()
    .json((response) => response);
};
