/* eslint-disable max-len */
import React from "react";
import { number, object } from "prop-types";

// import { captureTopNavigationClick } from "../../../helper/datalayer-utils";

const FitLogo = ({ width = 44, height = 38, member, isActivePlan, activePlanId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 56.298 42"
    // onClick={() =>
    //   setTimeout(
    //     captureTopNavigationClick("Fit", member && member, isActivePlan && isActivePlan, activePlanId && activePlanId),
    //     1000
    //   )
    // }
  >
    <path d="M0 0h56.3v42H0z" fill="rgba(255,255,255,0)" />
    <path
      d="M6.679 6.469v6.057h9.392a.946.946 0 0 1 1.043 1.044v4.345a.946.946 0 0 1-1.043 1.044H6.679v9.275a.946.946 0 0 1-1.043 1.044H1.043A.946.946 0 0 1 0 28.235V1.078A.946.946 0 0 1 1.043.033H16.28a.946.946 0 0 1 1.043 1.044v4.346a.946.946 0 0 1-1.043 1.044h-9.6zM22.1.1a1.332 1.332 0 0 0-.445-.069h-1.212A1.332 1.332 0 0 0 20 .1a.933.933 0 0 0-.6.975v27.16a.946.946 0 0 0 1.043 1.044h1.213a.946.946 0 0 0 1.044-1.044V1.078A.933.933 0 0 0 22.1.1zm22.5.975v4.348a.946.946 0 0 1-1.043 1.044H37.92v21.768a.946.946 0 0 1-1.043 1.044h-4.592a.946.946 0 0 1-1.043-1.044V6.469h-5.636a.946.946 0 0 1-1.043-1.044V1.078A.946.946 0 0 1 25.606.033h17.949A.946.946 0 0 1 44.6 1.078z"
      fill="#fff"
      transform="translate(5.828 5.8)"
    />
  </svg>
);

FitLogo.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  width: number,
  height: number,
};

export default FitLogo;
