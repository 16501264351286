/* eslint-disable max-len */
import React from "react";
import { number, string, object } from "prop-types";

// import { captureTopNavigationClick, captureBottomNavigationClick } from "../../../helper/datalayer-utils";

const HindiQuintLogo = ({ width = 90, height = 60, color = "#fff", position, member, isActivePlan, activePlanId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 51 42"
    // onClick={() => {
    //   position === "footer"
    //     ? setTimeout(
    //         captureBottomNavigationClick(
    //           "QuintHindi",
    //           member && member,
    //           isActivePlan && isActivePlan,
    //           activePlanId && activePlanId
    //         ),
    //         1000
    //       )
    //     : setTimeout(
    //         captureTopNavigationClick(
    //           "QuintHindi",
    //           member && member,
    //           isActivePlan && isActivePlan,
    //           activePlanId && activePlanId
    //         ),
    //         1000
    //       );
    // }}
  >
    <path d="M0 0H51V42H0z" fill="transparent" />
    <g transform="translate(1.223 1.381)">
      <g transform="translate(11.711 5.547)">
        <path
          d="M26.4 3.215a3.96 3.96 0 0 1 4.17-1.94c1.366.225 2.73 1.445 3.719 2.105a11.685 11.685 0 0 0 3.963 1.961 6.392 6.392 0 0 0 4.17-.186 4.39 4.39 0 0 0 1.3-.929 2.515 2.515 0 0 0 .619-1.094 2.964 2.964 0 0 1-.33-.144 1.926 1.926 0 0 1-.805.95 4.414 4.414 0 0 1-3.943.083A37.165 37.165 0 0 1 35.156 2.1a10.148 10.148 0 0 0-5.3-1.445 6.175 6.175 0 0 0-3.158 1.032c-2.5 1.631-3.612 5.264-4.376 7.782-.64 2.085-1.177 3.963-1.816 6.069h1.8c.888-2.787 1.672-5.47 2.6-8.257A17.839 17.839 0 0 1 26.4 3.215z"
          fill={color}
          className="hindi-logo-theme"
          transform="translate(-16.454 -.644)"
        />
        <path
          d="M50.874 37.655a4.121 4.121 0 0 0-1.528 3.945c.083.6.557 1.548 1.589 1.115a2.715 2.715 0 0 0 1.135-.908c.413-1.3.826-2.58 1.218-3.881 0-.021-.062-.1-.062-.124-.557-.888-1.589-.7-2.353-.144zm9.454.537a5.519 5.519 0 0 0-.6.867 6.266 6.266 0 0 0-.372 1.156l-.021.083v-.021a3.426 3.426 0 0 0 0 1.589c.206.661.743 1.115 1.362.888a2.152 2.152 0 0 0 1.2-.929c.413-1.4.805-2.807 1.218-4.211-.351-.392-1.28-.97-2.787.578z"
          fill="none"
          fillRule="evenodd"
          transform="translate(-39.314 -29.634)"
        />
        <path
          d="M64.062 29.344a1.263 1.263 0 0 1 .929 1.2h1.858c.33-1.011.6-2.25.908-3.241H42.409c-.041.144-.083.268-.124.413h5.325c-.062.351-.227.97-.372 1.445a2.458 2.458 0 0 0-1.61-.475 4.768 4.768 0 0 0-4.252 4.644 2.112 2.112 0 0 0 1.816 2.415 3.107 3.107 0 0 0 2.394-1.032c-.144.413-.5 1.424-.681 1.982h1.92c.392-1.362 1.548-5.016 1.9-6.069 0 0 .248-.826.351-1.259s.33-1.259.454-1.651h7.947c-.124.372-.268 1.135-.372 1.486a2.125 2.125 0 0 0-1.445-.516 3.986 3.986 0 0 0-1.94.433 2.846 2.846 0 0 0-.578.392 4.581 4.581 0 0 0-1.073 1.2c.021.021.021.041.041.062a6.575 6.575 0 0 0-.64 1.589 4.832 4.832 0 0 0-.186 1.218A2.08 2.08 0 0 0 53.2 35.8a2.684 2.684 0 0 0 2.188-1.011c-.165.537-.619 1.94-.619 1.94h1.878s2.209-7.39 2.725-9h6.316c-.165.516-.31 1.445-.475 1.9a1.786 1.786 0 0 0-1.383-.764c-2.374-.186-3.881 1.486-4.6 3.509a3.361 3.361 0 0 0 .351 3.53 3.284 3.284 0 0 0 3.365.681 4.568 4.568 0 0 0 2.828-2.229c-.165-.062-.351-.124-.516-.186a4.185 4.185 0 0 1-2.952 2.044c-.846.041-1.589-.248-1.589-1.527a8 8 0 0 1 .764-2.931c.372-.844 1.28-2.656 2.581-2.412zm-18.289 4.644a3 3 0 0 1-1.135.908c-1.053.433-1.527-.516-1.589-1.115a4.087 4.087 0 0 1 1.527-3.943c.764-.557 1.8-.764 2.374.165 0 .021.062.1.062.124-.412 1.26-.826 2.54-1.239 3.861zm9.846.021a2.223 2.223 0 0 1-1.2.929c-.64.206-1.156-.227-1.362-.888a3.426 3.426 0 0 1 0-1.589v.021l.021-.083a6.806 6.806 0 0 1 .372-1.156 4.267 4.267 0 0 1 .6-.867c1.507-1.569 2.436-.97 2.807-.557-.412 1.381-.825 2.781-1.238 4.19z"
          fill={color}
          className="hindi-logo-theme"
          transform="translate(-33.017 -21.798)"
        />
        <path
          d="M77 40.046c2.869-1.8 3.179.413 3.179.413a2.924 2.924 0 0 1 .165-.372c-.97-1.754-2.844-.433-3.344-.041z"
          fill={color}
          className="hindi-logo-theme"
          transform="translate(-61.292 -31.241)"
        />
        <g transform="translate(0 15.885)">
          <circle
            cx=".929"
            cy=".929"
            r=".929"
            fill={color}
            className="hindi-logo-theme"
            transform="translate(11.683)"
          />
          <path
            d="M82.055 48.4a1.744 1.744 0 0 0-.661 1.486 3.312 3.312 0 0 0 .186 1.094 2.679 2.679 0 0 0 1.693-1.38c.124-.31.289-.908.062-1.2a1 1 0 0 0-1.28 0z"
            fill="none"
            fillRule="evenodd"
            transform="translate(-64.778 -38.351)"
          />
          <path d="M19.772 27.362zM16.821 17H16.8" fill="#763996" transform="translate(-13.518 -13.615)" />
          <path
            d="M83.273 49.561c.124-.31.289-.908.062-1.2a1 1 0 0 0-1.28-.021 1.744 1.744 0 0 0-.661 1.486 6.444 6.444 0 0 0 .124.908c.021.062.062.124.083.186a2.6 2.6 0 0 0 1.672-1.359z"
            fill="none"
            fillRule="evenodd"
            transform="translate(-64.778 -38.311)"
          />
          <path
            d="M89.636 11.789v-.062a1.692 1.692 0 0 0-.826-1.362 2.077 2.077 0 0 0-1.837.144c-.351.186-.7.392-1.053.6-.165.083-2.312 1.445-2.972-.908-.083.041-.165.1-.248.144a3.2 3.2 0 0 0 1.115 1.92 2.373 2.373 0 0 0 2.828-.5 8.991 8.991 0 0 1 .826-.722.727.727 0 0 1 .578-.144.479.479 0 0 1 .289.392 4.878 4.878 0 0 1-.165.929c-.846 3.179-1.693 6.564-2.518 9.743h1.631c.722-2.766 1.424-5.532 2.147-8.3a8.062 8.062 0 0 0 .206-1.878z"
            fill={color}
            className="hindi-logo-theme"
            transform="translate(-65.815 -8.218)"
          />
          <path d="M16.821 17H16.8" fill="#763996" transform="translate(-13.518 -13.615)" />
          <path
            d="M5.379 10.179l.186-.679c.268-.784.826-1.94 1.858-1.693a5.558 5.558 0 0 1 .661.268c.681.351 1.218.908 1.858 1.3a4 4 0 0 0 1.982.619 3.559 3.559 0 0 0 3.344-2.188c-.083-.041-.165-.062-.268-.1a2.137 2.137 0 0 1-1.032 1.011 3.644 3.644 0 0 1-2.477.083 20.1 20.1 0 0 1-2.334-1.119A6.135 6.135 0 0 0 6.762 7a2.155 2.155 0 0 0-1.011.248 3.622 3.622 0 0 0-1.2 1.239 13.354 13.354 0 0 0-1.339 3.323c-.764 2.539-1.548 5.1-2.312 7.637h1.569c1.011-2.993 1.961-6.007 2.848-9.062.021-.083.041-.144.062-.206z"
            fill={color}
            className="hindi-logo-theme"
            transform="translate(-.9 -5.679)"
          />
          <path
            d="M20.347 23.841l-.124.392h5.491c-.186.6-.351.95-.516 1.486.021-.041-.1-.206-.144-.248a1.4 1.4 0 0 0-.826-.471 3.683 3.683 0 0 0-2.787.454 1.97 1.97 0 0 0-.661 2.5.02.02 0 0 0 .021.021.868.868 0 0 0 .041.1 1.873 1.873 0 0 0-.433.227 2.856 2.856 0 0 0-1.527 2.622c.1 1.9 2.56 2.044 3.943 1.651a4.442 4.442 0 0 0 1.548-.805 4.886 4.886 0 0 0 .578-.557c.041-.062.5-.722.5-.722-.186-.021-.351-.021-.578-.041a3.226 3.226 0 0 1-.454.6c-1.032 1.011-2.787 1.424-3.612.31a1.528 1.528 0 0 1 .165-1.8 3.56 3.56 0 0 1 1.651-1.135 5.816 5.816 0 0 1 2.374-.33c.33.041.743.124.846.5A.567.567 0 0 1 25.8 29a2.936 2.936 0 0 1-.289.454h.516a1.82 1.82 0 0 0 .206-.268 1.4 1.4 0 0 0 .268-.681c.083-.97-1.094-1.239-1.816-1.3a5.283 5.283 0 0 0-1.28.041c-.083 0-.991.186-.991.186a1.389 1.389 0 0 1 .083-1.362 1.417 1.417 0 0 1 2.518.475l.041-.021h1.858l.867-2.333h6.461l-.392 1.693s-.908-1.651-3.86 0a4.9 4.9 0 0 0-2.394 5.429c.454 1.424 2.085 1.569 3.3 1.135a.868.868 0 0 1 .1-.041v.021a1.985 1.985 0 0 1-.227 1.466v.021a1.394 1.394 0 0 1-1.527.6l-.083.248c-.021.041.392.124.433.124a1.336 1.336 0 0 0 .5-.021 2.344 2.344 0 0 0 1.486-.7 1.981 1.981 0 0 0 .392-.743 3.268 3.268 0 0 0 .186-.64 2.043 2.043 0 0 0 .021-.784v-.021c.083-.021.33-.124.351-.124a5.586 5.586 0 0 0 .764-.433 2.488 2.488 0 0 0 1.108-2.027 2.123 2.123 0 0 0-.124-.805.808.808 0 0 0-.206-.33 1.4 1.4 0 0 0-.95-.392 1.838 1.838 0 0 0-1.28.413 3.344 3.344 0 0 0-1.011 3.695c0 .021-2.353.557-1.8-2.291a6.561 6.561 0 0 1 1.672-3.3 3.8 3.8 0 0 1 .516-.392c.083-.041.144-.083.227-.124a3.3 3.3 0 0 1 .619-.268 1.162 1.162 0 0 1 1.466 1.115h1.878l.661-2.539a3.9 3.9 0 0 0 .132-.376zm12.1 5.222a.973.973 0 0 1 1.28.021c.227.289.062.888-.062 1.2a2.767 2.767 0 0 1-1.693 1.362s-.1-.351-.1-.392c-.041-.227-.062-.454-.083-.681v-.023a1.8 1.8 0 0 1 .661-1.486z"
            fill={color}
            className="hindi-logo-theme"
            transform="translate(-15.166 -19.011)"
          />
        </g>
        <circle
          cx=".991"
          cy=".991"
          r=".991"
          fill={color}
          className="hindi-logo-theme"
          transform="translate(24.688 .383)"
        />
        <path
          d="M75.386 40.661s2.394-2.044 4.232-.206a6.837 6.837 0 0 0-1.239 1.569s-.31-2.229-3.179-.413c0-.001.268-.95.186-.95z"
          fill={color}
          className="hindi-logo-theme"
          transform="translate(-59.863 -31.629)"
        />
      </g>
      <path
        d="M14.663 20.234l-6.234-6.729s-2.374-.041-3.261-.1c-2.457-.168-3.468-1.82-3.386-4.111 0 0 .021-3.261.1-4.768.124-2.25 1.239-3.034 3.488-2.89a40.1 40.1 0 0 1 5 .661 3.351 3.351 0 0 1 2.849 2.849c.248.929.743 3.695.97 4.933.248 1.383.144 2.849-1.734 3.2z"
        fill="#ffbf00"
      />
    </g>
  </svg>
);

HindiQuintLogo.propTypes = {
  width: number,
  height: number,
  color: string,
  position: string,
  member: object,
  isActivePlan: number,
  activePlanId: number,
};

export default HindiQuintLogo;
